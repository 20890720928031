import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"item-list"}},[_c(VRow,{staticClass:"mb-5"}),_c(VCard,[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VRow,{staticClass:"px-2 ma-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSelect,{attrs:{"label":_vm.t('Filter Product: Type'),"items":_vm.selectOptions.type,"item-text":"title","item-value":"value","clearable":"","dense":"","hide-details":"","open-on-clear":""},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSelect,{attrs:{"label":_vm.t('Filter Product: Dimension'),"items":_vm.selectOptions.visibility,"item-text":"title","item-value":"value","clearable":"","dense":"","hide-details":"","open-on-clear":""},model:{value:(_vm.dimensionVisibleFilter),callback:function ($$v) {_vm.dimensionVisibleFilter=$$v},expression:"dimensionVisibleFilter"}})],1)],1),_c(VDivider,{staticClass:"mt-4"}),_c('vuex-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"search":_vm.search,"meta":_vm.meta,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 25, 50, 100]
      }},on:{"update:search":function($event){_vm.search=$event},"update:meta":function($event){_vm.meta=$event},"update:options":function (o) { return _vm.options = o; }},scopedSlots:_vm._u([{key:"item.displayName",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.thumb ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.thumb ? '' : 'primary',"size":"32"}},[(item.thumb)?_c(VImg,{attrs:{"src":item.thumb}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.brand)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[(item.displayName)?_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'item-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.displayName)+" ")]):_c('span',[_vm._v("-")])],1)],1)]}},{key:"item.itemCategoryCode",fn:function(ref){
      var item = ref.item;
return [(item.itemCategoryId)?_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'item-category-view', params : { id : item.itemCategoryId } }}},[_vm._v(" "+_vm._s(item.itemCategoryCode)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.isDimensionHidden",fn:function(ref){
      var item = ref.item;
return [(item.isDimensionHidden)?_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-uppercase error--text",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.t('HIDE APP'))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name:'item-view', params:{ id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Item")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }