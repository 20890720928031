<template>
  <div id="item-list">
    <!-- user total card -->
    <v-row class="mb-5">
      <!--<v-col
        v-for="total in itemTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveItemTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveItemTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveItemTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveItemTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="typeFilter"
            :label="t('Filter Product: Type')"
            :items="selectOptions.type"
            item-text="title"
            item-value="value"
            clearable
            dense
            hide-details
            open-on-clear
          ></v-select>
        </v-col>

        <!-- visibility filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="dimensionVisibleFilter"
            :label="t('Filter Product: Dimension')"
            :items="selectOptions.visibility"
            item-text="title"
            item-value="value"
            clearable
            dense
            hide-details
            open-on-clear
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row
          align="center"
          align-content="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            md="8"
          >
            <table-search
              :search="options.q"
              dense
              outlined
              hide-details
              full-width
              @input="v => options = { ...options, q: v }"
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="items"
        :options="options"
        :search.sync="search"
        :meta.sync="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.displayName`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.thumb ? '' : 'primary'"
              :class="item.thumb ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.thumb"
                :src="item.thumb"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.brand) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                v-if="item.displayName"
                :to="{ name : 'item-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.displayName }}
              </router-link>
              <span v-else>-</span>
            </div>
          </div>
        </template>

        <!-- name -->
        <template #[`item.itemCategoryCode`]="{item}">
          <router-link
            v-if="item.itemCategoryId"
            :to="{ name : 'item-category-view', params : { id : item.itemCategoryId } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.itemCategoryCode }}
          </router-link>
          <span v-else>-</span>
        </template>

        <template #[`item.isDimensionHidden`]="{item}">
          <v-chip
            v-if="item.isDimensionHidden"
            small
            class="v-chip-light-bg font-weight-semibold text-uppercase error--text"
          >
            {{ t('HIDE APP') }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name:'item-view', params:{ id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Item</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiExportVariant } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import useItemList from './useItemList'

export default {
  setup() {
    const { t } = useUtils()
    const {
      itemListTable,
      tableColumns,
      totalItemListTable,
      loading,

      typeFilter,
      dimensionVisibleFilter,

      items,
      meta,

      setOptions,
      options,
      filters,
      search,

      init,
      destroy,
    } = useItemList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    const selectOptions = {
      // inventory: [
      //   { title: t('Negative Inventory'), value: '<0' },
      //   { title: t('Empty Inventory'), value: '0' },
      //   { title: t('Positive Inventory'), value: '>0' },
      // ],
      type: [
        { title: t('Type: Inventory'), value: 'inventory' },
        { title: t('Type: Service'), value: 'service' },
      ],
      visibility: [
        // { title: t('Show All Products'), value: false },
        { title: t('Show App Products'), value: true },
      ],
    }

    return {
      itemListTable,
      tableColumns,
      totalItemListTable,

      loading,

      selectOptions,

      typeFilter,
      dimensionVisibleFilter,

      items,
      meta,

      setOptions,
      options,
      filters,
      search,

      // icons
      icons: {
        mdiEyeOutline,
        mdiExportVariant,
      },

      t,
      avatarText,

      log: v => console.log(v),
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
