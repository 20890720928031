// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, readonly, ref, toRaw, watch } from '@vue/composition-api'

export default function useItemList() {
  const vm = getCurrentInstance().proxy
  const itemListTable = ref([])

  const tableColumns = [
    { text: 'No', value: 'number', width: '5%' },
    { text: 'NAME', value: 'displayName', width: '25%' },
    { text: 'BRAND', value: 'brand', width: '5%' },
    { text: 'TYPE', value: 'type', width: '10%' },
    { text: 'INVENTORY', value: 'inventory', width: '10%' },
    { text: 'PRICE', value: 'unitPrice', width: '10%' },
    { text: 'COST', value: 'unitCost', width: '10%' },
    { text: 'CATEGORY', value: 'itemCategoryCode', width: '15%' },
    {
      text: 'DIMENSION',
      value: 'isDimensionHidden',
      sortable: false,
      width: '5%',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '5%',
    },
  ]

  const items = computed({
    get: () => vm.$store.getters['items/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['items/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['items/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['items/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['items/options'],
    set: val => vm.$store.dispatch('items/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['items/search'],
    set: val => vm.$store.dispatch('items/setSearch', val),
  })
  const typeFilter = computed({
    get: () => vm.$store.getters['items/filters']?.type,
    set: val => vm.$store.dispatch('items/setFilters', { ...filters.value, type: val }),
  })
  const dimensionVisibleFilter = computed({
    get: () => vm.$store.getters['items/filters']?.dimensionVisible,
    set: val => vm.$store.dispatch('items/setFilters', { ...filters.value, dimensionVisible: val }),
  })
  const totalItemListTable = computed({
    get: () => vm.$store.getters['items/meta'].total,
  })

  const setOptions = val => vm.$store.dispatch('items/setOptions', val)
  const setFilters = val => vm.$store.dispatch('items/setFilters', val)
  const setSearch = val => vm.$store.dispatch('items/setSearch', val)
  const fetchItems = () => vm.$store.dispatch('items/fetchItems')

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    itemListTable,
    tableColumns,
    typeFilter,
    dimensionVisibleFilter,

    totalItemListTable,
    loading,

    fetchItems,

    items,
    meta,

    options,
    filters,
    search,

    setOptions,
    setFilters,
    setSearch,

    init,
    destroy,
  }
}
